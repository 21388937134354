module.exports = {
	name: 'plus-32',
	version: 3,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		'times-2': null,
		'times-3': null,
		'plus-1': null,
		'plus-2': null,
		'minus-1': null,
		'minus-2': null,
		add: null,
		sub: null,
		div: null,
		mul: null,
	},
	inputX: 2,
	outputX: 2,
	input: [
		6,
		28,
		496,
	],
	output: [
		38,
		60,
		528,
	],
	width: 5,
	height: 8,
	clockLimit: 50,
	statement: '演算子をうまく配置して、32を足してみよう!',
	title: '32を足してみよう',
};
