module.exports = {
	name: 'wire01',
	version: 4,
	parts: {
		wireI: null,
	},
	inputX: 1,
	outputX: 1,
	input: [
		10,
	],
	output: [
		10,
	],
	width: 3,
	height: 3,
	clockLimit: 10,
	statement: '入り口と出口をつなげてみよう!',
	title: '練習',
	modal: '01',
};
