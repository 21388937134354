module.exports = {
	name: 'make-plus-one',
	version: 1,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		'plus-1': null,
		'plus-2': null,
		'minus-1': null,
		'minus-2': null,
		add: null,
		sub: null,
		div: null,
		mul: null,
	},
	inputX: 2,
	outputX: 2,
	input: [
		6,
		28,
		496,
	],
	output: [
		1,
		1,
		1,
	],
	width: 5,
	height: 5,
	clockLimit: 25,
	statement: '何が入力されても、1を出力するような回路を作ってみよう！',
	title: '1を作ろう',
};
