module.exports = {
	name: 'power-easy',
	version: 3,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		wireX: null,
		'times-2': null,
		'times-3': null,
		'div-2': null,
		'div-3': null,
		'plus-1': null,
		'plus-2': null,
		'minus-1': null,
		'minus-2': null,
		'const-0': null,
		'const-1': null,
		'const-2': null,
		equal: null,
		add: null,
		sub: null,
		div: null,
		mul: null,
		mod: null,
		'c-contact': null,
		conditional: null,
		transistor: null,
		diode: null,
	},
	inputX: [
		3,
		5,
	],
	outputX: 4,
	input: [
		[
			3,
			2,
		],
		[
			4,
			4,
		],
		[
			-7,
			5,
		],
	],
	output: [
		9,
		256,
		-16807,
	],
	width: 9,
	height: 9,
	clockLimit: 200,
	statement: '1つ目に与えられた数を2つ目に与えられた数乗してみよう!',
	title: '累乗 -easy-',
};
