module.exports = {
	name: 'bivariation01',
	version: 3,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		'times-2': null,
		'times-3': null,
		'plus-1': null,
		'plus-2': null,
		'minus-2': null,
		equal: null,
		add: null,
		sub: null,
		div: null,
		mul: null,
		'c-contact': null,
		conditional: null,
		transistor: null,
		diode: null,
	},
	inputX: [
		1,
		3,
	],
	outputX: 2,
	input: [
		[
			3,
			2,
		],
		[
			13,
			21,
		],
		[
			9,
			4,
		],
	],
	output: [
		5,
		34,
		13,
	],
	width: 5,
	height: 5,
	clockLimit: 25,
	statement: '2つの数字が与えられるのでそれらを足しあわせた結果を出力してみよう!',
	title: '2変数 -基本-',
};
