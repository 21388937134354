module.exports = {
	name: 'addition-med',
	version: 1,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		wireX: null,
		wireXdot: null,
		'times-2': null,
		'times-3': null,
		'div-2': null,
		'div-3': null,
		'const-0': null,
		'const-1': null,
		'const-2': null,
		'const-3': null,
		equal: null,
		gt: null,
		lt: null,
		div: null,
		mul: null,
		pow: null,
		log: null,
		conditional: null,
		transistor: null,
		diode: null,
	},
	inputX: [2, 4],
	outputX: 3,
	input: [
		[1, 2],
		[8, 3],
		[33, 4],
	],
	output: [
		3,
		11,
		37,
	],
	width: 7,
	height: 7,
	clockLimit: 100,
	statement: '2つの正の数を足しあわせてみよう！',
	title: '足し算 -med-',
};
