module.exports = {
	name: 'remainder',
	version: 3,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		'times-2': null,
		'times-3': null,
		'plus-1': null,
		'plus-2': null,
		'minus-1': null,
		'minus-2': null,
		add: null,
		sub: null,
		div: null,
		mul: null,
	},
	inputX: 2,
	outputX: 2,
	input: [
		8,
		13,
		39,
	],
	output: [
		8,
		3,
		9,
	],
	width: 5,
	height: 10,
	clockLimit: 50,
	statement: '10で割った余りを求めてみよう!',
	title: '余りの計算',
};
