module.exports = {
	name: '1000',
	version: 3,
	parts: {
		wireI: null,
		wireL: null,
		wireT: null,
		'times-2': null,
		'const-1': null,
		add: null,
		sub: null,
		mul: null,
	},
	inputX: 2,
	outputX: 2,
	input: [
		-1,
		10,
		1,
	],
	output: [
		1000,
		1000,
		1000,
	],
	width: 5,
	height: 5,
	clockLimit: 25,
	statement: '1000を作ろう!',
	title: '1000',
};
